import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JobsTable from './JobsTable';
import Loader from '../common/Loader';
import { loadPractices, loadUsers } from '../../features/table/tableActions';
import { JOB_DETAILS_MODAL } from '../../constants/modals';
import JobDetailsModal from './details/JobDetailsModal';
import { modalClosed } from '../../features/modal/modalSlice';
import { isAdmin } from '../../helpers/userHelper';
import { ErrorContext } from '../../ErrorContext';
import { refreshPage } from '../../helpers/commonHelper';
export default function Jobs() {
  const { setErrorAlert } = useContext(ErrorContext);
  const [isLoaderVisible, setLoaderVisible] = useState(true);
  const modal = useSelector((state) => state.modal);
  const { userInfo } = useSelector((state) => state.user);
  const { users, practices, preLoadingError } = useSelector(
    (state) => state.tableData
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!practices) {
      dispatch(loadPractices());
    }
  }, [practices]);

  useEffect(() => {
    if (!users && isAdmin(userInfo)) {
      dispatch(loadUsers());
    }
  }, [users]);

  useEffect(() => {
    if ((users || !isAdmin(userInfo)) && practices) {
      setLoaderVisible(false);
    }
  }, [users, practices]);

  useEffect(() => {
    if (preLoadingError) {
      setErrorAlert({
        error: preLoadingError,
        buttonText: 'Try reloading',
        onButtonClick: refreshPage,
        closingButton: true,
      });
    }
  }, [preLoadingError]);

  return (
    <>
      {isLoaderVisible && !preLoadingError && <Loader />}
      {!isLoaderVisible && !preLoadingError && (
        <div id="jobs" className="flex flex-col h-full flex-grow-1">
          <JobsTable />
        </div>
      )}
      <JobDetailsModal
        onClose={() => dispatch(modalClosed())}
        isOpen={modal.visibleModal === JOB_DETAILS_MODAL}
        title="Details"
      />
    </>
  );
}
